import { ChangeEvent, FormEvent, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { Box, Typography, FormControl, OutlinedInput, Button, CircularProgress, Divider } from "@mui/material";
import { ApiService } from "../../services/api.service";
import { depositScbAccountType } from "../../types";
import toast, { Toaster } from "react-hot-toast";
import Swal from "sweetalert2";
import ScbLogo from './../../assets/images/bank_logo/scb.png';
import DiceSpin from "../Loading/DiceSpin";
import { io } from 'socket.io-client';
const socket = io('https://api.betwon.com');

const SubDeposit = () => {
    const navigate = useNavigate();
    const cookies = new Cookies(null, { path: '/' });
    const [loading, setLoading] = useState<boolean>(false);
    const amountRef = useRef<HTMLInputElement>(null);
    const [accountDetail, setAccountDetail] = useState<depositScbAccountType | null>(null);
    const [loadingAccount, setLoadingAccount] = useState<boolean>(false);
    const [processing, setProcessing] = useState<boolean>(false);
    const [showScbAccount, setShowScbAccount] = useState<boolean>(false);
    const [amount, setAmount] = useState<number>(0);
    const [transferAmount, setTransferAmount] = useState<number>(0);
    const [transferIn, setTransferIn] = useState<Date | null>(null);

    useEffect(() => {
        socket.on('message', (msg) => {
            if(msg.type == 'scb_deposit_app')
            {
                if(cookies.get('access_token') && cookies.get('user') == msg.ufa_username)
                {
                    setShowScbAccount(false);
                }
            }
        });

        return () => {
            socket.off('message');
        };
    }, []);

    const isOnlyDigits = (str: string) => {
        const pattern = /^\d+$/;
        return pattern.test(str);
    }

    useEffect(() => {
        getBankAccountActive();
    }, []);

    const calculateTimeLeft = () => {
        if(typeof(transferIn) !== 'string')
        {
            return null;
        }

        const now = new Date().getTime();
        const difference = new Date(transferIn).getTime() - now;
        let timeLeft: { days?: number; hours?: number; minutes?: number; seconds?: number } = {};
        
        if(difference > 0)
        {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
                minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
                seconds: Math.floor((difference % (1000 * 60)) / 1000),
            };
        }
    
        return timeLeft;
    }

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
    useEffect(() => {
        const timer = setTimeout(() => {
            if(transferIn)
            {
                setTimeLeft(calculateTimeLeft());
            }
        }, 1000);
    
        return () => clearTimeout(timer);
    }, [timeLeft, transferIn]);

    const createPaymentScb = async (amount: number) => {
        try {
            const service = new ApiService();
            const createPayment = await service.createPaymentScb(cookies.get('access_token'), amount);

            if(createPayment.data.status_code === 200)
            {
                setAmount(amount);
                setTransferAmount(createPayment.data.res.transfer_amount);
                setTransferIn(createPayment.data.res.transfer_in);
                setShowScbAccount(true);
            }
            else
            {
                Swal.fire({
                    icon: 'error',
                    title: 'แจ้งเตือน',
                    text: `เกิดข้อผิดพลาด กรุณาลองใหม่ภายหลัง - ${createPayment.data.status_code} ${createPayment.data.message}`
                });
            }

            setProcessing(false);
        } catch (err) {
            console.log(err);
            setProcessing(false);
        }
    }

    const submit_deposit = async (e: FormEvent) => {
        e.preventDefault();
        try {
            if(!amountRef.current || amountRef.current.value.trim() === '')
            {
                return;
            }

            const amount = amountRef.current.value.trim();
            if(!isOnlyDigits(amount))
            {
                return Swal.fire({
                    icon: 'error',
                    title: 'แจ้งเตือน',
                    text: 'กรุณากรอกจำนวนที่ต้องการฝากเป็นตัวเลขเท่านั้น (ไม่ต้องมีทศนิยม)'
                });
            }
            
            setProcessing(true);
            const service = new ApiService();
            if(parseInt(amount) < 100)
            {
                setShowScbAccount(true);
                // await createPaymentScb(parseInt(amount));
            }
            else
            {
                const getEndpoint = await service.getEndpointPay();
                if(getEndpoint.data.status_code !== 200)
                {
                    setShowScbAccount(true);
                    // await createPaymentScb(parseInt(amount));
                }
                else
                {
                    const endpointPath = getEndpoint.data.data.path;
                    const endpoint = getEndpoint.data.data.deposit_endpoint;
                    const request = await service.requestPay(cookies.get('access_token'), amount, endpointPath, endpoint);
                    if(request.data.status_code === 200 && request.data.redirect_url)
                    {
                        window.location.href = request.data.redirect_url;
                    }
                    else
                    {
                        setShowScbAccount(true);
                        // await createPaymentScb(parseInt(amount));
                    }
                }
            }

            setProcessing(false);
        } catch (err) {
            console.log(err);
            setProcessing(false);
        }
    }

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        if(!event.target)
        {
            return;
        }

        const newValue = event.target.value;
        const inputElement = document.getElementById('deposit_amount') as HTMLInputElement;

        if (/^\d*$/.test(newValue))
        {
            if(inputElement)
            {
                inputElement.value = newValue.replace("+", "");
            }
        }
        else
        {
            const numericValue = newValue.replace(/\D/g, '');
            if(inputElement)
            {
                inputElement.value = numericValue.replace("+", "");
            }
        }
    }

    const getBankAccountActive = async () => {
        setLoadingAccount(true);
        try {
            const service = new ApiService();
            const getAccount = await service.getAccountActive(cookies.get('access_token'));

            if(getAccount.data.status_code !== 200)
            {
                return;
            }

            setAccountDetail(getAccount.data.data);
            setLoadingAccount(false);
        } catch (err) {
            console.log(err);
        }
    }

    const copyToClipboard = (text: string) => {
        navigator.clipboard.writeText(text);
        toast.success('คัดลอกเรียบร้อยแล้ว');
    }
    
    return (
        processing ? <Box display={`flex`} justifyContent={`center`} my={3}>
            <DiceSpin />
        </Box> : (
            !showScbAccount ? <form name="user_deposit" id="user_deposit" onSubmit={submit_deposit}>
                <Box bgcolor={'white'} sx={{ borderRadius: '0px 0px 15px 15px' }}>
                    <Box p={2}>
                        
                        <Typography align="center" fontSize={20} color={'#313131'}>
                            ฝากเงิน
                        </Typography>
                        <Typography align="center" color={'#9c9c9c'}>
                            กรอกจำนวนเงินที่ต้องการฝาก
                        </Typography>
                        <FormControl fullWidth sx={{ mt: 1 }}>
                            <OutlinedInput
                                id='deposit_amount'
                                name='deposit_amount'
                                type={`number`}
                                size='small'
                                onChange={handleChange}
                                fullWidth
                                autoComplete="deposit_amount"
                                placeholder="จำนวนเงินที่ต้องการฝาก (จำนวนเงินบาท)"
                                inputRef={amountRef}
                                sx={
                                    {
                                        backgroundColor: 'white'
                                    }
                                }
                            />
                        </FormControl>
                        <Button type="submit" fullWidth sx={
                            {
                                mt: 1,
                                py: 1,
                                backgroundColor: '#ec2230',
                                color: 'white',
                                '&:hover': {
                                    backgroundColor: '#c61d29',
                                    color: 'white',
                                }
                            }
                        } disabled={processing}>
                            <Typography fontSize={16} fontWeight={500}>
                                {
                                    processing ? <CircularProgress size={20} sx={
                                        {
                                            color: 'white'
                                        }
                                    } /> : "ยืนยันการฝากเงิน"
                                }
                            </Typography>
                        </Button>
                        <Divider sx={{ my: 1 }} />
                        <Typography align="center" fontSize={12} color={'red'}>
                            หมายเหตุ: หากต้องการรับโปรโมชั่น กรุณารับโปรโมชั่นก่อนทำการฝากเงินทุกครั้ง
                        </Typography>
                    </Box>
                </Box>
            </form> : (accountDetail) ? <Box bgcolor={'white'} sx={{ borderRadius: '0px 0px 15px 15px' }}>
                <Box p={2}>
                    <Toaster />
                    <Box display={`flex`} justifyContent={`center`}>
                        <img src={ScbLogo} alt="SCB Logo" style={{ maxWidth: 75 }} />
                    </Box>
                    <Divider sx={{ color: '#9c9c9c' }}>รายละเอียด</Divider>
                    <Typography align="center" fontSize={16} color={'#313131'}>
                        ธนาคาร: ไทยพาณิชย์ (SCB)
                    </Typography>
                    <Typography align="center" fontSize={16} color={'#313131'}>
                        ชื่อบัญชี: { accountDetail?.bank_account_name }
                    </Typography>
                    <Typography align="center" fontSize={16} color={'#313131'}>
                        เลขบัญชี: { accountDetail?.bank_account_number }
                    </Typography>
                    {/* <Typography align="center" fontSize={16} fontWeight={`bold`} color={'#313131'}>
                        ยอดเงินที่ต้องโอน: {
                            parseFloat(String(transferAmount)).toLocaleString('en-US', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            })
                        }
                    </Typography>
                    <Typography align="center" fontSize={14} fontWeight={`bold`} color={'#313131'}>
                        กรุณาโอนเงินภายใน:&nbsp;
                        {
                            typeof(timeLeft?.days) === 'number' && timeLeft?.days > 0 && `${timeLeft?.days} วัน `
                        }
                        {
                            typeof(timeLeft?.hours) === 'number' && timeLeft?.hours > 0 && `${timeLeft?.hours} ชั่วโมง `
                        }
                        {
                            typeof(timeLeft?.minutes) === 'number' && timeLeft?.minutes > 0 && `${timeLeft?.minutes} นาที `
                        }
                        {
                            typeof(timeLeft?.seconds) === 'number' ? `${timeLeft?.seconds} วินาที ` : '0 วินาที'
                        }
                    </Typography>
                    <Divider sx={{ my: 1 }} />
                    <Typography align="center" fontSize={12} color={'red'}>
                        หมายเหตุ: กรุณาโอนเงินตามยอดที่ถูกต้องเท่านั้น หากโอนเงินไม่ถูกต้องตามยอดระบบจะไม่สามารถฝากเงินให้ท่านได้ค่ะ
                    </Typography> */}
                    <Divider sx={{ my: 1 }} />
                    <Typography align="center" fontSize={12} color={'red'}>
                        หมายเหตุ: กรุณาใช้บัญชีธนาคารที่สมัครเข้ามาเพื่อสมัครเท่านั้น มิเช่นนั้นยอดฝากที่โอนมาจะไม่สามารถเพิ่มเครดิตอัตโนมัติให้ได้ค่ะ
                    </Typography>
                    <Button type="button" fullWidth size="small" sx={
                        {
                            mt: 1,
                            py: 1,
                            backgroundColor: '#ec2230',
                            color: 'white',
                            '&:hover': {
                                backgroundColor: '#c61d29',
                                color: 'white',
                            }
                        }
                    } onClick={() => copyToClipboard(`${accountDetail?.bank_account_number}`)}>
                        <Typography fontSize={14} fontWeight={500}>
                            คัดลอกเลขบัญชี
                        </Typography>
                    </Button>
                    <Divider sx={{ mt: 1 }} />
                    <Button fullWidth sx={
                        {
                            mt: 1,
                            backgroundColor: '#9c9c9c',
                            color: 'white',
                            '&:hover': {
                                backgroundColor: '#696969',
                                color: 'white',
                            }
                        }
                    } onClick={() => setShowScbAccount(false)}>
                        <Typography fontSize={16} fontWeight={500}>
                            ย้อนกลับ
                        </Typography>
                    </Button>
                </Box>
            </Box> : <Box display={`flex`} justifyContent={`center`} my={3}>
                <DiceSpin />
            </Box>
        )
    )
}

export default SubDeposit;